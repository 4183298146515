import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import { Button } from 'react-bootstrap';
export const ProfilePage = () => {
  const { user, getAccessTokenSilently} = useAuth0(); // Import getAccessTokenSilently
  const [userMetadata, setUserMetadata] = useState(null);

  async function updateUserMetaData() {
    const domain = "dev-sszy8q0ldpee8vfb.us.auth0.com"
  
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "update:current_app_metadata",
        ignoreCache: true,
      });
  
      const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
      const currentTimestampInSeconds = Math.floor(Date.now() / 1000); // This will give you the current epoch timestamp in seconds
  
      const { user_metadata } = await (
        await fetch(userDetailsByIdUrl, {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_metadata: { testData: currentTimestampInSeconds },
          }),
        })
      ).json();
  
      setUserMetadata(user_metadata);
    } catch (e) {
      console.log(e.message);
    }
  };
  
  async function getUserMetaData() {
    const domain = "dev-sszy8q0ldpee8vfb.us.auth0.com";
  
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
        ignoreCache: true,
      });
  
      const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
  
      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      const { user_metadata } = await metadataResponse.json();
  
      setUserMetadata(user_metadata);
    } catch (e) {
      console.log(e.message);
    }
  };

  if (!user) {
    return null;
  }
  console.log(userMetadata);
  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Profile Page
        </h1>
        <div className="content__body">
          <p id="page-description">
            <span>
              You can use the <strong>ID Token</strong> to get the profile
              information of an authenticated user.
            </span>
            <span>
              <strong>Only authenticated users can access this page.</strong>
            </span>
          </p>
          <div className="profile-grid">
            <div className="profile__header">
              <img
                src={user.picture}
                alt="Profile"
                className="profile__avatar"
              />
              <div className="profile__headline">
                <h2 className="profile__title">{user.name}</h2>
                <span className="profile__description">{user.email}</span>
              </div>
            </div>
            <div className="profile__details">
              <CodeSnippet
                title="Decoded ID Token"
                code={JSON.stringify(user, null, 2)}
              />
              <Button style={{ backgroundColor: "#d69859" }} className="text-center btn-lg" onClick={() => updateUserMetaData()} variant="light">updateUserMetaData</Button>
              <Button style={{ backgroundColor: "#d69859" }} className="text-center btn-lg" onClick={() => getUserMetaData()} variant="light">getUserMetaData</Button>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
