import React from "react";
import { Auth0Feature } from "./auth0-feature";

export const Auth0Features = () => {
  const featuresList = [
    {
      title: "Uncensored",
      description:
        "Content is always fresh, exciting, and tailored to your deepest desires. So why wait? Subscribe now and let's get this party started!",
      resourceUrl: "https://auth0.com/docs/connections",
      icon: "https://midnight-society-login.vercel.app/uncensored.png",
    },
    {
      title: "Personalized Experience",
      description:
        "Chat, call and exchange pictures with your favorite influencer, Solina, 24/7 in 40+ languages.",
      resourceUrl: "https://auth0.com/docs/multifactor-authentication",
      icon: "https://midnight-society-login.vercel.app/official-logo.png",
    },
  ];

  return (
    <div className="auth0-features">
      <div className="auth0-features__grid">
        {featuresList.map((feature) => (
          <Auth0Feature
            key={feature.resourceUrl}
            title={feature.title}
            description={feature.description}
            resourceUrl={feature.resourceUrl}
            icon={feature.icon}
          />
        ))}
      </div>
    </div>
  );
};
