import React from "react";

export const HeroBanner = () => {
  const logo = "https://midnight-society-login.vercel.app/official-logo.png";

  return (
    <div className="hero-banner">
      <div className="hero-banner__logo">
        <img className="hero-banner__image" src={logo} alt="React logo" />
      </div>
      <h1 className="hero-banner__headline text-white">Solina SAI</h1>
      <p className="hero-banner__description text-white">
      Your ultimate guide to all things <strong>NAUGHTY and NICE.</strong>
      </p>
    </div>
  );
};
