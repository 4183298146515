import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import crypto from "crypto-js"; // Import the crypto-js library

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  const [token, setToken] = useState(""); // State to store the generated token

  const generateToken = () => {
    const timestamp = new Date().getTime().toString(); // Get Unix timestamp
    const otherData = "YourOtherDataHere"; // Include any other relevant data
    const tokenData = timestamp + otherData; // Concatenate data
    const sha256Hash = crypto.SHA256(tokenData); // Generate SHA-256 hash
    setToken(sha256Hash.toString(crypto.enc.Hex)); // Store the hash in the state
  };

  const handleLogin = async () => {
    await loginWithRedirect({
      prompt: "login",
      appState: {
        returnTo: "/chat",
      },
    });
    console.log("login");
    generateToken();
    console.log("Generated Token:", token);
  };

  // After successful login, you can use the 'token' state variable to make API calls.

    // Example API call using the generated token (replace with your API endpoint and logic)
    // fetch('YOUR_API_ENDPOINT', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${token}`, // Include the generated token in the headers
    //   },
    //   body: JSON.stringify({}),
    // })
    // .then(response => response.json())
    // .then(data => {
    //   console.log("API Response:", data);
    // })
    // .catch(error => {
    //   console.error("API Error:", error);
    // });
  return (
    <button className="button__login" onClick={handleLogin}>
      Log In
    </button>
  );
};
