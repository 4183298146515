import { useAuth0 } from "@auth0/auth0-react";
import { React } from "react";
import { PageLayout } from "../components/page-layout";
import { loadStripe } from '@stripe/stripe-js';
import "react-toastify/dist/ReactToastify.css";
import { Accordion, Card, Button } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

export const Subscription = () => {
  const { user } = useAuth0();
  if (!user) {
    return null;
  }
  
  function PricingDetails() {
    return (
      <div className="container mt-4">
      <Accordion className="content__title custom-accordion" defaultActiveKey="0" flush>
        <Accordion.Item className="content__title custom-accordion" eventKey="0">
          <Accordion.Header className="content__title accordion-header custom-accordion">Text Replies</Accordion.Header>
          <Accordion.Body className="content__title custom-accordion">
            Text replies cost 1 credit each. Enjoy engaging dialogues, and captivating conversations with your favorite personalities.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="custom-accordion" eventKey="1">
          <Accordion.Header className="accordion-header">Audio Replies</Accordion.Header>
          <Accordion.Body>
            Audio replies cost 2 credits each. Elevate your interactions and enjoy a deeper connection with ultra-realistic audio messages.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="custom-accordion" eventKey="2">
          <Accordion.Header className="accordion-header">Pictures</Accordion.Header>
          <Accordion.Body>
            Pictures can be unlocked on a pay-per-view basis. Indulge in unique moments, enjoy the thrill, and add an extra layer of excitement to your interactions.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="custom-accordion" eventKey="3">
          <Accordion.Header className="accordion-header">SMS/Calls</Accordion.Header>
          <Accordion.Body>
            Option will be available soon. Elevate your interactions and enjoy a deeper connection with ultra-realistic audio messages.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      </div>
    );
  }
  
  function BronzeCard() {
    const priceId = "price_1OboNqHqsjQqxk7HGt2A30xD";

    return (
      <Card style={{backgroundColor: '#000000' }} className="text-white">
        <Card.Img variant="top" style={{ width: '150px', height: '150px', margin: '0 auto' }} src="https://midnight-society-login.vercel.app/official-logo-bronze-400x400.png" />
        <Card.Body className="text-center">
          <Card.Title className="fs-1 text-center text-white">$5</Card.Title>
          <Card.Text style={{ color: "#ffffff" }} className="content__title text-left">
            500 Credits
          </Card.Text>
          <Button style={{ backgroundColor: "#d69859" }} className="text-center btn-lg" onClick={() => handleCheckout(priceId)} variant="light">Buy</Button>
        </Card.Body>
      </Card>
    );
  }
  function SilverCard() {
    const priceId = "price_1OcBGCHqsjQqxk7HEx18lyqR";

    return (
      <Card style={{backgroundColor: '#000000' }} className="text-white">
        <Card.Img variant="top" style={{ width: '150px', height: '150px', margin: '0 auto' }} src="https://midnight-society-login.vercel.app/official-logo-silver-400x400.png" />
        <Card.Body className="text-center">
          <Card.Title className="fs-1 text-center text-white">$10</Card.Title>
          <Card.Text style={{ color: "#ffffff" }} className="content__title text-left">
            1000 Credits
          </Card.Text>
          <Button style={{ backgroundColor: "#d1d1d1" }} className="text-center  btn-lg" onClick={() => handleCheckout(priceId)} variant="light" >Buy</Button>
        </Card.Body>
      </Card>
    );
  }
  function GoldCard() {
    const priceId = "price_1OcBHGHqsjQqxk7HC2IUpeaj";

    return (
      <Card style={{backgroundColor: '#000000' }} className="text-white">
        <Card.Img variant="top" style={{ width: '150px', height: '150px', margin: '0 auto' }} src="https://midnight-society-login.vercel.app/official-logo-gold-400x400.png" />
        <Card.Body className="text-center">
          <Card.Title className="fs-1 text-center text-white">$20</Card.Title>
          <Card.Text style={{ color: "#ffffff" }} className="content__title text-left">
            2250 Credits
          </Card.Text>
          <Button style={{ backgroundColor: "#ffd700"}} className="text-center btn-lg" onClick={() => handleCheckout(priceId)} variant="light" >Buy</Button>
        </Card.Body>
      </Card>
    );
  }
  function PlatinumCard() {
    const priceId = "price_1OcBHiHqsjQqxk7HY9m6gB94";

    return (
      <Card style={{backgroundColor: '#000000' }} className="text-white">
        <Card.Img variant="top" style={{ width: '150px', height: '150px', margin: '0 auto' }} src="https://midnight-society-login.vercel.app/official-logo-platinum-400x400.png" />
        <Card.Body className="text-center">
          <Card.Title className="fs-1 text-center">$50</Card.Title>
          <Card.Text style={{ color: "#ffffff" }} className="content__title text-left">
            5500 Credits
          </Card.Text>
          <Button style={{ backgroundColor: "#cbcbca" }} className="text-center btn-lg" onClick={() => handleCheckout(priceId)} variant="light" >Buy</Button>
        </Card.Body>
      </Card>
    );
  }
  function DiamondCard() {
    const priceId = "price_1OcBHiHqsjQqxk7HY9m6gB94";

    return (
      <Card style={{backgroundColor: '#000000' }} className="text-white">
        <Card.Img variant="top" style={{ width: '150px', height: '150px', margin: '0 auto' }} src="https://midnight-society-login.vercel.app/official-logo-diamond-400x400.png" />
        <Card.Body className="text-center">
          <Card.Title className="fs-1 text-center">$100</Card.Title>
          <Card.Text style={{ color: "#ffffff" }} className="content__title text-left">
            11000 Credits
          </Card.Text>
          <Button style={{ backgroundColor: "#35b4b4" }} className="text-center btn-lg" onClick={() => handleCheckout(priceId)} variant="light" >Buy</Button>
        </Card.Body>
      </Card>
    );
  }
  function EliteCard() {
    const priceId = "price_1OcBHiHqsjQqxk7HY9m6gB94";

    return (
      <Card style={{backgroundColor: '#000000' }} className="text-white">
        <Card.Img variant="top" style={{ width: '150px', height: '150px', margin: '0 auto' }} src="https://midnight-society-login.vercel.app/official-logo-elite-400x400.png" />
        <Card.Body className="text-center">
          <Card.Title className="fs-1 text-center">$200</Card.Title>
          <Card.Text style={{ color: "#ffffff" }} className="content__title text-left">
            22000 Credits
          </Card.Text>
          <Button style={{ backgroundColor: "#b70000" }} className="text-center btn-lg" onClick={() => handleCheckout(priceId)} variant="light" >Buy</Button>
        </Card.Body>
      </Card>
    );
  }


  let stripePromise;
  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe("pk_test_51ObZM9HqsjQqxk7HMmPyUpjjogm9nR25SavXoN23c0plkbXnHx9dYxEWtLnBpulUUrGZQnXLsnAV02lMJqCVU7aZ00LNO6O7sO");
    }
    return stripePromise;
  };

  async function handleCheckout(priceId) {
    const stripe = await getStripe();
    console.log("stripe", stripe);
    //console.log("process.env.PUBLIC_STRIPE_PUBLISHABLE_KEY", process.env.PUBLIC_STRIPE_PUBLISHABLE_KEY)
    //console.log("process.env.PUBLIC_STRIPE_BRONZE_PRICE_ID", process.env.PUBLIC_STRIPE_BRONZE_PRICE_ID)
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: priceId,
          quantity: 1,
        },
      ],
      mode: "payment",
      successUrl: "https://chat.solina.ai/success",
      cancelUrl: "https://chat.solina.ai/cancel",
      customerEmail: 'customer@email.com',
    });
    console.warn(error.message);
  }
  
  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Subscriptions
        </h1>
        <div className="content__body">
          <p id="page-description">
            <span>
              Choose a package that best suits your desires.
            </span>
            <span>
              <div className="content__title container">
                <div className="row">
                  <div className="col-md-6 col-lg-2">
                    <BronzeCard />
                  </div>
                  <div className="col-md-6 col-lg-2">
                    <SilverCard />
                  </div>
                  <div className="col-md-6 col-lg-2">
                    <GoldCard />
                  </div>
                  <div className="col-md-6 col-lg-2">
                    <PlatinumCard />
                  </div>
                  <div className="col-md-6 col-lg-2">
                    <DiamondCard />
                  </div>
                  <div className="col-md-6 col-lg-2">
                    <EliteCard />
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <span>
                      <PricingDetails />
                    </span>
                  </div>
                </div>
              </div> 
            </span>
          </p>
        </div>
      </div>
    </PageLayout>
  );
};
