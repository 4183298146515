import React from "react";
import { NavLink } from "react-router-dom";

export const NavBarBrand = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink to="/">
        <img
          className="nav-bar__logo"
          src="https://midnight-society-login.vercel.app/official-logo.png"
          alt="Solina SAI"
          //width="122"
          //height="36"
        />
      </NavLink>
    </div>
  );
};
