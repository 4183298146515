import React, { useEffect, useState } from "react";
import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import { getProtectedResource } from "../services/message.service";
import { useAuth0 } from "@auth0/auth0-react"; // Import Auth0 library

//This doesnt appear to be a protected route
export const Wallet = () => {
  const [message, setMessage] = useState("");
  const { user, isAuthenticated } = useAuth0(); // Retrieve user and authentication status from Auth0
  console.log("isAuthenticated", isAuthenticated);
  const setTimeInMetadata = async () => {
    if (!user) {
      return;
    }

    // Construct the metadata update payload
    const updatedMetadata = {
      ...user.user_metadata, // Preserve existing user metadata
      currentTime: new Date().toISOString(), // Set the current time
    };

    // Update the user's metadata
    try {
      await user.updateUserMetadata(updatedMetadata);
    } catch (error) {
      console.error("Error updating user metadata:", error);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const getMessage = async () => {
      const { data, error } = await getProtectedResource();

      if (!isMounted) {
        return;
      }

      if (data) {
        setMessage(JSON.stringify(data, null, 2));
      }

      if (error) {
        setMessage(JSON.stringify(error, null, 2));
      }
    };

    getMessage();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Wallet
        </h1>
        <div className="content__body">
          <p id="page-description">
            <span>
              This page retrieves a <strong>protected message</strong> from an
              external API.
            </span>
            <span>
              <strong>Only authenticated users can access this page.</strong>
            </span>
          </p>
          <button onClick={setTimeInMetadata}>Set Current Time</button> {/* Add the button */}
          <CodeSnippet title="Protected Message" code={message} />
        </div>
      </div>
    </PageLayout>
  );
};
